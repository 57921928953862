import { useEffect, useState } from 'react';
import { ContactInfo } from '../components/ContactInfo';
import { MapSection } from '../components/MapSection';
import { PageLayout } from '../components/PageLayout';
import GoToTop from '../hooks/GoToTop';
import { Loading } from '../components/Loading';

export const Contact = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formLoaded, setFormLoaded] = useState(false); 

  useEffect(() => {
    fetch('/data/contact.json')
      .then((response) => response.json())
      .then((data) => {
        setContactData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching contact data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.body.style.background = document.querySelector('.page-layout')?.classList.contains('page-layout--gray') ? '#F8F9FB' : 'white';

    return () => {
      document.body.style.background = 'white';
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/49210589.js';
    script.defer = true;
    script.onload = () => setFormLoaded(true); // Mark the script as loaded when the onload event fires
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <PageLayout title='Contact' description='Contact' noContainer gray>
        <div className='contact'>
          <div className='container'>
            {loading ? <Loading /> : <ContactInfo data={contactData} />}
            {formLoaded && (
              <div className='hs-form-frame' data-region='na1' data-form-id='cd7ecfdb-4855-44fa-9f10-0964136f3cf3' data-portal-id='49210589'></div>
            )}
            <div className='dt-hide'>
              <MapSection page='contact' />
            </div>
          </div>
        </div>

        <div className='mb-hide'>
          <MapSection page='contact' />
        </div>

      <GoToTop />
    </PageLayout>
  );
};
