import { useEffect, useState } from 'react'
import { PageLayout } from '../components/PageLayout'
import GoToTop from '../hooks/GoToTop'
import { Loading } from '../components/Loading'
import useMobile from '../hooks/useMobile'
import ImageGallery from "react-image-gallery"
import imageGallery from 'react-image-gallery'

export const Galerie = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

    const mobile = useMobile()

    useEffect(() => {
        fetch('/data/gallery_images.json')
          .then((response) => response.json())
          .then((data) => {
            setImages(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching contact data:', error);
            setLoading(false);
          });
      }, []);

    const galleryImages = images.map((url) => ({
        original: url,
        thumbnail: `${url}?w=250&h=150`,
    }));


    useEffect(() => {
        if (document.querySelector('.page-layout').classList.contains('page-layout--gray')) {
            document.body.style.background = '#F8F9FB'
        } else {
            document.body.style.background = 'white'
        }

        return () => {
            document.body.style.background = 'white'
        }
    })

    function scroll() {
        var offset = 0
        if (mobile) {
            offset = 230
        } else {
            offset = 480
        }

        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        scroll()

    })

    return (
        <PageLayout title='Galerie' description={'Galerie'}>

            {!imageGallery || loading ? (
                <Loading />
            ) : (
                <ImageGallery items={galleryImages} />
            )}
            <GoToTop />
        </PageLayout>
    )
}