import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import vamt from '../assets/images/vamt.png';
import vamt_2 from '../assets/images/vamt@2x.png';
import { ReactSVG } from 'react-svg';
import whatsapp_icon from '../assets/icons/whatsapp.svg';
import facebook_icon from '../assets/icons/facebook-brands.svg';
import { FooterNote } from './FooterNote';
import { useState, useEffect } from 'react';
import { ModalDialog } from './ModalDialog';
import { Loading } from '../components/Loading';

export const Footer = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [modalOpen, setModalOpen] = useState(false);

  let location = useLocation();

  useEffect(() => {
    fetch('/data/contact.json')
      .then((response) => response.json())
      .then((data) => {
        setContactData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching contact data:', error);
        setLoading(false);
      });
  }, []);

  const { address = '', whatsapp = '', phone_1 = '', email = '', facebook = '' } = contactData || {};

  const preFooterVisible = location.pathname === '/' ? false : true;

  return (
    <>
      <footer>
        <div className='footer'>
          {loading ? (
            <Loading />
          ) : (
            <>
              {preFooterVisible && (
                <div className='footer__pre-footer'>
                  <div className='mb-hide'>
                    <h4>SUNA ACUM PENTRU A PROGRAMA</h4>
                    <h3>O VIZIONARE</h3>
                  </div>
                  <div className='dt-hide'>
                    <h4>PROGRAMEAZA O</h4>
                    <h3>VIZIONARE</h3>
                  </div>
                  <a href={`tel:${phone_1}`}>
                    <div className='footer__pre-footer__phone'>
                      <div>
                        <div className='footer__pre-footer__phone-icon'>
                          <i className='fas fa-phone invert'></i>
                        </div>
                        <div className='footer__pre-footer__phone-number'>
                          <span>{phone_1}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              )}
              <div className='footer__main-footer'>
                <div className='footer__main-footer__top'>
                  <div className='container'>
                    <div className='footer__main-footer__top-brand'>
                      <Link to='/'>
                        <img src={logo} alt='VAMT | Rezidential Vest' />
                      </Link>
                    </div>
                    <div>Pagini</div>
                    <div>Contact</div>
                  </div>
                </div>
                <div className='footer__main-footer__content'>
                  <div className='container'>
                    <div>
                      <h5>
                        {address.split(' ').slice(0, -1).join(' ')}
                        <br></br>
                        {address.split(' ').slice(-1).join(' ')}
                      </h5>

                      <div className='footer__main-footer__content-credits'>
                        <div>
                          Proiect dezvoltat de <b>VAMT</b>
                        </div>
                        <img src={vamt} srcSet={vamt_2} alt='vamt logo' />
                      </div>
                    </div>
                    <div className='footer__main-footer__content-pages'>
                      <ul>
                        <li>
                          <Link to='/'>Proiect</Link>
                        </li>
                        <li>
                          <Link to='/apartamente'>Apartamente</Link>
                        </li>
                        <li>
                          <Link to='/finantare'>Finantare</Link>
                        </li>
                        <li>
                          <Link to='/galerie'>Galerie</Link>
                        </li>
                        <li>
                          <Link to='/contact'>Contact</Link>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <span></span>
                      <div className='contact__info-item'>
                        <ReactSVG src={whatsapp_icon} />
                        <a href={whatsapp} target='_blank' rel='noreferrer'>
                          <p className='contact__info-item--wp'>Contacteaza-ne pe WhatsApp</p>
                        </a>
                      </div>
                      <div className='contact__info-item'>
                        <span>
                          <i className='fal fa-phone invert'></i>
                        </span>
                        <a href={`tel:${phone_1}`}>
                          <p>{phone_1}</p>
                        </a>
                      </div>
                      {/* <div className="contact__info-item">
                                        <span><i className="fal fa-phone invert"></i></span>
                                        <a href={`tel:${phone_2}`}>
                                            <p>{phone_2}</p>
                                        </a>
                                    </div> */}
                      <div className='contact__info-item'>
                        <span>
                          <i className='fal fa-envelope'></i>
                        </span>
                        <a className='dt-hide' href={`mailto:${email}`}>
                          <p>{email}</p>
                        </a>
                        <p className='mb-hide'>{email}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='footer__main-footer__bottom'>
                  <div className='container'>
                    <div className='footer__main-footer__bottom-fb'>
                      <a href={facebook} target='_blank' rel='noreferrer'>
                        <ReactSVG src={facebook_icon} />
                        <span>NOUTATI</span>
                      </a>
                    </div>
                    <div className='footer__main-footer__bottom-copy'>Copyright © Rezidential Vest, {new Date().getFullYear()}</div>
                    <div className='text-center'>
                      <span className='cursor-pointer' onClick={() => setModalOpen(true)}>
                        Politica de confidentialitate
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <FooterNote />
            </>
          )}
        </div>
      </footer>

      <ModalDialog open={modalOpen} closeAction={() => setModalOpen(false)} title='Politica de confidentialitate'>
        <div className='text-left'>
          <p className='mb-10'>
            <strong>1. CINE SUNTEM?</strong>
          </p>
          <p>
            VAMT EXPERT IMOB S.R.L. este o societate cu sediul în Paulesti - Prahova având numărul de ordine în Registrul Comerțului J29/3459/2019,
            cod unic de înregistrare 42026197.
          </p>
          <p>
            Colectăm și prelucrăm diferite categorii de date personale de la dumneavoastră în calitate de utilizator al website-ului nostru, ceea ce,
            în conformitate cu legislaţia Uniunii Europene privind protecția datelor personale, ne conferă calitatea de operator de date pentru astfel
            de date.
          </p>
          <p>
            Protecţia datelor dumneavoastră este foarte importantă pentru noi. Prezenta notă de informare cu privire la Prelucrarea Datelor Personale
            descrie modalitatea noastra privind colectarea și utilizarea de către noi a datelor dumneavoastră personale ca urmare a interacţiunii cu
            website-ul nostru.
          </p>
          <p className='mt-20 mb-10'>
            <strong>2. CE DATE PRELUCRĂM DESPRE DUMNEAVOASTRĂ? </strong>
          </p>
          <p>
            2.1 <strong>Atunci când vizitaţi website-ul nostru, prelucrăm următoarele date:</strong>
          </p>
          <p>2.1.1 Utilizarea website-ului:</p>
          <p>
            2.1.1.1 Website-ul colectează în mod automat anumite informații și le stochează. Aceste informații includ adresa de IP, locația generală
            în care se află computerul sau dispozitivul dumneavoastră, tipul de browser, sistemul de operare, momentul accesării, link-ul paginii
            accesate, istoricul de vizualizare al paginilor website-ului nostru, informații despre dispozitiv, programe vizualizate, id dispozitiv.
          </p>
          <p>
            2.1.1.2 Folosim aceste informații pentru a ne putea concepe site-ul şi aplicaţia în așa fel încât să se adapteze mai bine la nevoile
            utilizatorilor noștri. De asemenea, este posibil să vă folosim adresa IP pentru a putea contribui la diagnosticarea problemelor legate de
            serverele noastre.
          </p>
          <p>
            2.1.1.3 Temeiul prelucrării este interesul nostru legitim de a monitoriza activitatea pe site şi a asigura securitatea site-ului. Datele
            sunt păstrate timp de un an de la data vizitei.
          </p>
          <p className='mt-20 mb-10'>
            <strong>3. PARTAJĂM DOAR ANUMITE PARȚI DIN DATELE DUMNEAVOASTRĂ, DOAR DACA SUNT NECESARE ȘI NUMAI UNOR CATEGORII DE TERȚI:</strong>
          </p>
          <p>3.1 Altor societăți cu care putem dezvolta programe comune de ofertare pe piață a bunurilor si serviciilor noastre;</p>
          <p>
            3.2 Altor entitaţi cum ar fi autoritățile și instituțiile publice, contabilii, auditorii, avocații și alți consilieri profesionali
            externi, în cazul în care activitatea acestora necesită cunoașterea acestora sau în cazul în care legea ne impune să le divulgăm;
          </p>
          <p className='mt-20 mb-10'>
            <strong>4. VOM DIVULGA DATELE DUMNEAVOASTRĂ UNOR TERȚI ÎN URMATOARELE SITUAȚII:</strong>
          </p>
          <p>4.1 În cazul în care ne solicitaţi sau ne daţi acordul în acest sens;</p>
          <p>4.2 Persoanelor care pot demonstra că deţin autoritatea legală de a acţiona în numele dumneavoastră; </p>
          <p>
            4.3 În cazul în care este interesul nostru legitim să facem acest lucru pentru a administra, extinde sau dezvolta activitatea comercială;{' '}
          </p>
          <p>
            4.5 În cazul în care avem obligaţia de a dezvălui datele dumneavoastră personale pentru a respecta o obligație legală, orice solicitare
            legală din partea autorităţilor guvernamentale sau executive, şi după cum poate deveni necesar pentru a îndeplini anumite cerinţe de
            securitate națională sau de aplicare a legii sau a de preveni anumite activităţi ilegale;{' '}
          </p>
          <p>
            4.6 Pentru a răspunde oricăror pretenţii, pentru a ne proteja drepturile noastre sau ale unui terţ, pentru a proteja siguranţa oricărei
            persoane sau pentru a preveni orice activitate ilegală.
          </p>
          <p className='mt-20 mb-10'>
            <strong>5. MODIFICĂRI ALE POLITICII DE CONFIDENȚIALITATE </strong>
          </p>
          <p>
            5.1 Modificări ale prezentei politici de confidențialitate ce vor apărea pe parcurs vor fi publicate în această pagina web fără o
            informare prealabilă. “Termeni și Condiții” constituie, în întregime, un acord încheiat între dumneavoastră și Societate în privința
            utilizării acestui site web. Societatea își rezervă dreptul de a revizui și aduce la zi aceste reguli în orice moment, fără o anunțare sau
            o acceptare prealabilă a utilizatorilor.
          </p>
          <p className='mt-20 mb-10'>
            <strong>6. INFORMAȚII PENTRU UTILIZATORI</strong>
          </p>
          <p>
            <strong>
              Vă rugăm să luați în considerare faptul că, în calitate de utilizator (vizitator), responsabilitățile dumneavoastră sunt urmatoarele:
            </strong>
          </p>
          <p>6.1 Atunci când situația cere, să modificați datele de înregistrare pentru a fi reale, corecte și complete.</p>
          <p>
            6.2 Ne rezervăm dreptul de a prelucra și folosi informațiile pe care ni le furnizați pentru a vă informa asupra acțiunilor de marketing și
            ofertelor companiei.
          </p>
          <p className='mt-20 mb-10'>
            <strong>7. ÎN CALITATE DE UTILIZATOR VĂ ASUMAȚI URMATOARELE OBLIGAȚII:</strong>
          </p>
          <p>
            7.1 Să nu publicați materiale care conțin viruși sau alte programe distructive, care au rolul de a distruge acest sistem sau orice sistem
            sau informație conexe acestui site web;
          </p>
          <p>
            7.2 Să nu publicați materiale cu drept de autor dacă nu sunteți autorul sau dacă nu aveți permisiunea autorului de a publica materialele
            respective;
          </p>
          <p>7.3 Să nu publicați materiale obscene, denigratoare, de amenințare sau răuvoitoare față de un alt utilizator, persoană fizica.</p>
          <br></br>
          <br></br>
          <p className='mt-20 mb-10'>
            <strong>Ce sunt cookie-urile?</strong>
          </p>
          <p>
            Cookie-urile sunt fişiere text de mici dimensiuni pe care un site web le stochează în computerul sau dispozitivul dumneavoastră mobil
            atunci când îl accesaţi. Cum folosim cookie-urile? Cookie-urile sunt folosite pentru a înregistra informaţii referitoare la modul în care
            folosiţi site-ul nostru web, de exemplu când aţi vizitat ultima dată{' '}
            <a href='www.rezidentialvest.ro' target='_blank' rel='noreferrer'>
              www.rezidentialvest.ro
            </a>{' '}
            şi ce fel de informaţii aţi căutat aici. Fişierul text nu poate fi citit decât de acest site web, astfel că nimeni altcineva nu are acces
            la aceste informaţii.
          </p>
          <br></br>
          <br></br>
          <p>
            Unele dintre pagini folosesc cookie-urile pentru a înregistra: setările ecranului, precum cele legate de culoare, contrast şi mărimea
            textului dacă aţi fost deja de acord (sau nu) cu utilizarea cookie-urilor pe acest site preferinţele legate de limbă orice informaţii
            introduse (mai puţin elementele confidenţiale). Astfel, nu este necesar să le introduceţi de fiecare dată când accesaţi site-ul. Nu este
            necesară activarea cookie-urilor pentru ca acest site să funcţioneze, dar ele asigură o calitate mai bună a navigării. Aceste cookie-uri
            pot fi şterse sau blocate, dar în acest caz unele elemente ale site-ului ar putea funcţiona incorect.
          </p>
          <br></br>
          <br></br>
          <p>
            Informaţiile legate de cookie-uri nu sunt utilizate pentru identificare, iar datele legate de activitatea dumneavoastră sunt protejate de
            noi şi nu sunt divulgate altor entităţi.
          </p>
          <br></br>
          <br></br>
          <p>
            Acest site web foloseşte Google Analytics, un serviciu de analiză web furnizat de Google, Inc. (Google). Informaţiile generate de acest
            cookie la accesarea acestui site (inclusiv adresa dumneavoastră IP) vor fi transmise serverelor Google şi vor fi stocate acolo. Google va
            folosi aceste informaţii pentru a evalua utilizarea domeniului, va genera rapoarte de activitate pentru administratori şi va furniza alte
            servicii legate de activitatea site-ului şi utilizarea Internetului. Google poate transmite aceste informaţii altor entităţi când acest
            lucru este impus prin lege sau când aceste entităţi procesează datele în numele Google. Google nu va lega niciodată adresa dumneavoastră
            IP de alte date stocate de Google. Prin utilizarea acestui site, oferiţi permisiunea Google să proceseze informaţii legate de
            dumneavoastră în limitele şi scopurile prezentate mai sus.
          </p>
          <br></br>
          <br></br>
          <p>
            Pentru mai multe informaţii despre cookie-urile Google Analytics cookies, apăsaţi aici. Cum pot controla cookie-urile şi împiedica
            primirea lor? Puteţi controla şi şterge cookie-uri, în funcţie de preferinţe. Pentru mai multe informaţii despre cum poate fi dezactivată
            stocarea cookie-urilor şi despre schimbarea setărilor pentru navigatorul web, vizitaţi{' '}
            <a href='http://www.aboutcookies.org' target='_blank' rel='noreferrer'>
              http://www.aboutcookies.org
            </a>
            . Puteţi şterge toate cookie-urile stocate în computer. În plus, cele mai multe navigatoare web pot fi configurate pentru a bloca
            cookie-urile. În acest caz însă, veţi fi nevoiţi să ajustaţi manual câteva setări de fiecare dată când accesaţi site-ul web, iar unele
            servicii şi funcţii ar putea funcţiona incorect.
          </p>
        </div>
      </ModalDialog>
    </>
  );
};
