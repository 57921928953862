import { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import proiectIcon from '../assets/icons/research.svg'
import ofertaIcon from '../assets/icons/keys.svg'
import finantaretIcon from '../assets/icons/loan.svg'
import galerieIcon from '../assets/icons/image.svg'
import contactIcon from '../assets/icons/talk.svg'
import { ReactSVG } from 'react-svg'
import useScrollPosition from "../hooks/useScrollPosition"
import { Loading } from '../components/Loading';

export const Navbar = () => {
    const [contactData, setContactData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(true)

    const scrollPos = useScrollPosition()
    let location = useLocation()

    useEffect(() => {
        fetch('/data/contact.json')
          .then((response) => response.json())
          .then((data) => {
            setContactData(data);
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching contact data:', error);
            setLoading(false);
          });
      }, []);

    useEffect(() => {

        if (location.pathname.includes('/apartament')) {

            if (window.innerWidth > 1023) {

                if (scrollPos > 420) {
                    setVisible(false)
                    return () => {
                        setVisible(true)
                    }
                }
            } else {
                if (scrollPos > 210) {
                    setVisible(false)
                    return () => {
                        setVisible(true)
                    }
                }
            }
        }
    }, [visible, location.pathname, scrollPos])

    useEffect(() => {

        if (location.pathname.includes('/galerie')) {

            if (window.innerWidth > 1023) {

                if (scrollPos > 420) {
                    setVisible(false)
                    return () => {
                        setVisible(true)
                    }
                }
            } else {
                if (scrollPos > 210) {
                    setVisible(false)
                    return () => {
                        setVisible(true)
                    }
                }
            }
        }
    }, [visible, location.pathname, scrollPos])
    return (
        <header>
               {loading ? (
                        <Loading />
                      ) : (
                        <>
            {visible &&
                <nav className="navbar" id="navbar">
                    <div className="container">
                        <div className="d-flex jc-sb ai-center">
                            <div className="d-flex">
                                <div className="navbar__brand">
                                    <Link to="/">
                                        <img src={logo} alt="VAMT  | Rezidential Vest" />
                                    </Link>
                                </div>
                                <div className="navbar__contact">
                                    <div className="navbar__contact-phone">
                                        <i className="fal fa-phone invert"></i>
                                      
                                            <a className="navbar__phonelink" href={`tel:${contactData.phone_1}`}><span>{contactData.phone_1}</span></a>
                                      
                                    </div>
                                    <div className="navbar__contact-address">
                                        <i className="fal fa-home"></i>
                                        <span>Str. Targoviștei, Nr.12, Ploiești</span>
                                    </div>
                                </div>
                            </div>

                            <div className="navbar__menu--desktop">
                                <NavLink
                                    exact={true}
                                    to="/"
                                    className="navbar__menu__link"
                                    activeClassName="navbar__menu__link--active"
                                >
                                    Proiect
                                </NavLink>
                                <NavLink
                                    to="/apartamente"
                                    className="navbar__menu__link"
                                    activeClassName="navbar__menu__link--active"
                                >
                                    Apartamente
                                </NavLink>
                                <NavLink
                                    to="/finantare"
                                    className="navbar__menu__link"
                                    activeClassName="navbar__menu__link--active"
                                >
                                    Finantare
                                </NavLink>
                                <NavLink
                                    to="/galerie"
                                    className="navbar__menu__link"
                                    activeClassName="navbar__menu__link--active"
                                >
                                    Galerie
                                </NavLink>
                                <NavLink
                                    to="/contact"
                                    className="navbar__menu__link"
                                    activeClassName="navbar__menu__link--active"
                                >
                                    Contact
                                </NavLink>

                            </div>

                        </div>
                    </div>
                </nav>
            }
            <div className="navbar__menu--mobile">
                <NavLink
                    exact={true}
                    to="/"
                    className="navbar__menu__link"
                    activeClassName="navbar__menu__link--active"
                >
                    <ReactSVG src={proiectIcon} />
                    <span>Proiect</span>
                </NavLink>
                <NavLink
                    to="/apartamente"
                    className="navbar__menu__link"
                    activeClassName="navbar__menu__link--active"
                >
                    <ReactSVG src={ofertaIcon} />
                    <span>Oferta</span>
                </NavLink>
                <NavLink
                    to="/finantare"
                    className="navbar__menu__link"
                    activeClassName="navbar__menu__link--active"
                >
                    <ReactSVG src={finantaretIcon} />
                    <span>Finantare</span>
                </NavLink>
                <NavLink
                    to="/galerie"
                    className="navbar__menu__link"
                    activeClassName="navbar__menu__link--active"
                >
                    <ReactSVG src={galerieIcon} />
                    <span>Galerie</span>
                </NavLink>
                <NavLink
                    to="/contact"
                    className="navbar__menu__link"
                    activeClassName="navbar__menu__link--active"
                >
                    <ReactSVG src={contactIcon} />
                    <span>Contact</span>
                </NavLink>
            </div>
                        </>
                      )
                      }
        </header>
    )
}